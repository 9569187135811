import { GetUserInfo, UserInfoOptions } from '@root/api/getUserInfo';
import { convertCardMask, converExpiry } from '@root/utils/convertors';
import { getFormattedPrice } from '@root/utils/getFormattedPriceLocale';

export type UserData = {
  payAmount: string;
  fee: string;
  creditId: number;
  cardMask?: string;
  expiry?: string;
  description: string;
  apayEnabled: boolean;
  gpayEnabled: boolean;
  options: Partial<UserInfoOptions>;
  fullAmount: string;
  token?: string;
  editCardMask: boolean;
  isChecked: boolean;
};

const getAmount = (amount: number) => (amount ? amount / 100 : 0);

export const formatUserData = (data: GetUserInfo): UserData => ({
  description: data.description,
  payAmount: getFormattedPrice(getAmount(data.amount)),
  fullAmount: getFormattedPrice(getAmount(data.fullAmount)),
  creditId: data.tranId || 0,
  cardMask: data.pan && convertCardMask(data.pan),
  expiry: converExpiry(data.expiry),
  fee: getFormattedPrice(getAmount(data.fee)),
  options: data.options || {},
  token: data.token,
  apayEnabled: data.apayEnabled,
  gpayEnabled: data.gpayEnabled,
  editCardMask: data.editCardMask,
  isChecked: data.isChecked || false
});
