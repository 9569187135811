export const statusResposeSuccess = {
  statusCode: '1000',
  codeDesc: 'Успішно',
  receiptLink: 'https://processing.com/trx/receipt/get/{uuid}',
  merchantLink: 'https://selfie.credit/loan',
  statusDescription: null,
  amount: 300.0
};

export const statusResposeError = {
  statusCode: '6000',
  codeDesc: 'Транзакція не закінчена',
  receiptLink: 'https://processing.com/trx/receipt/get/{uuid}',
  merchantLink: 'https://selfie.credit/loan',
  statusDescription:
    'Для збільшення ліміта оплат у інтернеті:\n 1) зайдіть у свій мобільний банкінг.\n 2) оберіть «налаштування карти».\n 3) ліміти-> збільшити ліміт оплати картою у Інтернет.\n 4) рекомендована сума: 300.00',
  amount: 300.0
};
