export const SELFIE_MERCHANT = '2';

export const selfieVerify = (param?: string) => {
  if (param) {
    return `/2/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const selfieVerifySuccess = (param?: string) => {
  if (param) {
    return `/2/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const selfieVerifyFailed = (param?: string) => {
  if (param) {
    return `/2/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const selfiePay = (param?: string) => {
  if (param) {
    return `/2/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const selfiePaySuccess = (param?: string) => {
  if (param) {
    return `/2/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const selfiePayFailed = (param?: string) => {
  if (param) {
    return `/2/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};

// export const selfiePayAnon = (param?: string) => {
//   if (param) {
//     return `/${SELFIE_MERCHANT}/pay/anon` as const;
//   }
//   return 'pay/anon' as const;
// };

export const selfiePayQr = () => 'pay/qr' as const;

export const selfieFailed = () => `/${SELFIE_MERCHANT}/failed` as const;
