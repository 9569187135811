import { checkinSanctionList } from '@root/api/getUserInfo';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ButtonWithSpin from '~/components/ButtonWithSpin';
import CustomField from '~/components/CustomField';
import CustomWrapperFields from '~/components/CustomWrapperFields';
import RawFields from '~/components/RawFields';
import { useCheckContext } from '~/context/CheckContext';
import '~/scss/default/main.scss';
import './ModalCheckForm.scss';
import ModalContainer from './ModalContainer';
import { useValidation } from './useCheckFormValidation';

export type ModalFormInputs = {
  client_name: string;
  document?: string;
  inn: string;
  date_bd: string;
};

const ModalCheckForm = () => {
  const { state, setCheckData, setIsCheck } = useCheckContext();
  const navigate = useNavigate();
  const { checkFormValidation } = useValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid }
  } = useForm<ModalFormInputs>({
    resolver: checkFormValidation,
    mode: 'onChange',
    defaultValues: {
      client_name: '',
      inn: '',
      date_bd: ''
    }
  });

  const onSubmit = (values: ModalFormInputs) => {
    return checkinSanctionList(values).then((data) => {
      if (data.isCheckPassed) {
        setCheckData(values);
        setIsCheck(false);
      } else {
        navigate(`/status?tranId=${state.tranId}`);
      }
    });
  };

  if (state && state.isCheck) {
    return (
      <ModalContainer>
        <section className="modal-check">
          <form
            className={'modal-check__form'}
            onSubmit={handleSubmit(onSubmit)}
          >
            <h1 className="modal-check__title">Дані платника</h1>
            <CustomWrapperFields label={'Ім`я'} error={errors['client_name']}>
              <RawFields control={control} name={'client_name'} />
            </CustomWrapperFields>
            <CustomField
              label={'ІПН'}
              error={errors['inn']}
              control={control}
              name={'inn'}
              mask={'9999999999'}
            />
            <CustomField
              label={'Дата народження'}
              error={errors['date_bd']}
              control={control}
              name={'date_bd'}
              mask={'99.99.9999'}
            />
            <ButtonWithSpin
              disabled={isSubmitting || !isValid}
              buttonText="Перевірити"
            />
          </form>
        </section>
      </ModalContainer>
    );
  }
  return null;
};

export default ModalCheckForm;
