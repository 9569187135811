import { selfiePayQr } from '@root/constants/paths';
import CustomQr from '@comp/CustomQr';
import qrImg from '@root/images/selfie/qr.png';

export const getQrRoute = () => [
  {
    path: selfiePayQr(),
    element: <CustomQr qrImg={qrImg} />
  }
];
