import { lazy } from 'react';
import { SELFIE_MERCHANT } from '@root/constants/paths';
import { getSelfieVerifyRoutes } from './selfieVerifyRoutes';
import { getSelfieContentData } from '@root/constants/formData';
import { getSelfiePayRoutes } from './selfiePayRoutes';
// import { getSelfieAnonRoutes } from './getSelfieAnonRoutes';
import { getFailRoute } from './getFailRoute';
import { getQrRoute } from './getQrRoute';
import { getLookupRoute } from './getLookupRoute';

const CustomLayout = lazy(() => import('@root/components/CustomLayout'));

const { header, footer } = getSelfieContentData();

export const selfieRoutes = {
  path: SELFIE_MERCHANT,
  element: <CustomLayout headerContent={header} footerContent={footer} />,
  children: [
    ...getSelfieVerifyRoutes(),
    ...getSelfiePayRoutes(),
    ...getLookupRoute(),
    // ...getSelfieAnonRoutes(),
    ...getQrRoute(),
    ...getFailRoute()
  ]
};
