const fillFields = 'Заповніть, будь ласка, поле';
const notValidFormat = 'Невірний формат';
const notValidCvv = 'Не вірний CVV';
const notValidCard = 'Не валідна карта';
const notValidDate = 'Не валідна дата';

export const errorTextValidation = {
  cardNumber: {
    required: fillFields,
    matches: notValidFormat,
    lunaValidation: notValidCard
  },
  expiry: {
    required: fillFields,
    matches: notValidFormat,
    expiryValidation: notValidDate
  },
  cvv: { required: fillFields, matches: notValidCvv }
} as const;
