import style from '@root/scss/credit7/index.module.scss';
import { Oval } from 'react-loader-spinner';
import { useParams } from 'react-router';
import ErrorRedirect from '~/components/ErrorRedirect/ErrorRedirect';
import { OnSubmitParams, useOnSubmit } from '~/hooks/useOnSubmit';
import { useUserData } from '~/queries/useUserData';
import { Form } from './Form';

type MainAvansPlusProps = {
  redirectPaths: OnSubmitParams['redirectPaths'];
};

export const StarFinMain = (props: MainAvansPlusProps) => {
  const { id } = useParams();
  const { redirectPaths } = props;
  const { userData, errorInfo } = useUserData(id);
  const { onSubmit } = useOnSubmit({
    redirectPaths,
    merchant:
      userData && userData.options.merchant ? userData.options.merchant : '',
    token: userData ? userData.token : undefined
  });
  const urlContainsPay = window.location.href.includes('verify');

  if (errorInfo) {
    return <ErrorRedirect errorInfo={errorInfo} classBtn="btn-bg" />;
  }

  if (userData) {
    return (
      <>
        <Form
          userData={userData}
          onSubmit={onSubmit}
          urlContainsPay={urlContainsPay}
        />
      </>
    );
  }

  return (
    <section className={style.blockForm}>
      <div className={style.container_540} style={{ marginTop: '105px' }}>
        <Oval wrapperClass={style['centred-block']} />
      </div>
    </section>
  );
};
