import moment from 'moment';

export const validateDate = (value: string | undefined) => {
  if (value) {
    const [userMonth, userYear] = value.split('/');
    const year = parseInt(moment().format('YY'));
    const month = parseInt(moment().format('M'));
    if (
      parseInt(userMonth) > 12 ||
      parseInt(userMonth) < 1 ||
      parseInt(userYear) < year ||
      (parseInt(userMonth) < month && parseInt(userYear) <= year)
    ) {
      return false;
    }
  }

  return true;
};

export const validateDateByConditions = (value: string | undefined) => {
  if (value) {
    const [userMonth, userYear] = value.split('/');
    const year = 22;
    const month = 2;
    if (
      parseInt(userMonth) > 12 ||
      parseInt(userMonth) < 1 ||
      parseInt(userYear) < year ||
      (parseInt(userMonth) < month && parseInt(userYear) <= year)
    ) {
      return false;
    }
  }

  return true;
};
