import { customLookupPath } from '@root/constants/paths';
import CustomLookupForm from '@comp/CustomLookup';
import { formPayData, getSlonContentData } from '@root/constants/formData';
const { main } = getSlonContentData();

export const getLookupRoute = () => [
  {
    path: customLookupPath(),
    element: <CustomLookupForm formData={formPayData} mainContent={main} />
  }
];
