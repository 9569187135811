import { lazy } from 'react';
import { CREDIT7_MERCHANT } from '@root/constants/paths';
import { getCredit7VerifyRoutes } from './credit7VerifyRoutes';
import { getCredit7PayRoutes } from './credit7PayRoutes';
import { getLookupRoute } from './getLookupRoute';

const LayoutK7 = lazy(() => import('@pages/credit7/LayoutK7'));

export const credit7Route = {
  path: CREDIT7_MERCHANT,
  element: <LayoutK7 />,
  children: [
    ...getCredit7VerifyRoutes(),
    ...getCredit7PayRoutes(),
    ...getLookupRoute()
  ]
};
