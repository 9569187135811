export const CREDIT_PLUS_MERCHANT = '5';

export const creditPlusVerify = (param?: string) => {
  if (param) {
    return `/${CREDIT_PLUS_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const creditPlusVerifySuccess = (param?: string) => {
  if (param) {
    return `/${CREDIT_PLUS_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const creditPlusVerifyFailed = (param?: string) => {
  if (param) {
    return `/${CREDIT_PLUS_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const creditPlusPay = (param?: string) => {
  if (param) {
    return `/${CREDIT_PLUS_MERCHANT}/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const creditPlusPaySuccess = (param?: string) => {
  if (param) {
    return `/${CREDIT_PLUS_MERCHANT}/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const creditPlusPayFailed = (param?: string) => {
  if (param) {
    return `/${CREDIT_PLUS_MERCHANT}/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};
