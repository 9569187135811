import { useRoutes as Routes } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';
import { routes } from './routes';
import { prefetchUserData } from './utils/prefetchUserData';
import { createPortal } from 'react-dom';
import ModalCheckForm from './modal/ModalCheckForm';
import CheckProvider from './context/CheckContext';

prefetchUserData();
const useRoutes = wrapUseRoutes(Routes);

function App() {
  const content = useRoutes(routes);
  return (
    <>
      <CheckProvider>
        {content}
        {createPortal(
          <ModalCheckForm />,
          document.getElementById('check') as HTMLElement
        )}
      </CheckProvider>
    </>
  );
}

export default App;
