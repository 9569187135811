import { lazy } from 'react';
import CustomFailed from '~/components/CustomFailed';
import CustomSuccess from '~/components/CustomSuccess';
import { starFinVerifySuccess } from '~/constants/paths/pathStarfin';
import {
  SUN_CREDIT_MERCHANT,
  sunPay,
  sunPayFailed,
  sunPaySuccess
} from '~/constants/paths/sunCredit';
import { SunMain } from '~/pages/SunCredit/SunMain';

const LayoutSunCredit = lazy(() => import('~/pages/SunCredit/index'));
export const sunCreditRoute = {
  path: SUN_CREDIT_MERCHANT,
  element: <LayoutSunCredit />,
  children: [
    {
      path: 'verify/:id',
      element: (
        <SunMain
          redirectPaths={{
            success: starFinVerifySuccess,
            failed: sunPayFailed
          }}
        />
      )
    },
    {
      path: 'pay/:id',
      element: (
        <SunMain
          redirectPaths={{
            success: sunPaySuccess,
            failed: sunPayFailed
          }}
        />
      )
    },
    {
      path: sunPaySuccess(),
      element: <CustomSuccess />
    },
    {
      path: sunPayFailed(),
      element: <CustomFailed redirectPath={sunPay} />
    }
  ]
};
