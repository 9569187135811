import { lazy } from 'react';
import { getPaytechContentData } from '@root/constants/formData';
import Status from '@comp/Status';

const CustomLayout = lazy(() => import('@root/components/CustomLayout'));

const { header, footer } = getPaytechContentData();

export const statusPageRoute = {
  path: 'status',
  element: <CustomLayout headerContent={header} footerContent={footer} />,
  children: [
    {
      index: true,
      element: <Status />
    }
  ]
};
