import { getUserInfo } from '@root/api/getUserInfo';
import { queryKeys } from '@root/queries/routesName';
import { QueryClient } from '@tanstack/react-query';
import { formatUserData } from './formatUserData';

export const prefetchUserData = () => {
  const client = new QueryClient();
  const url = window.location.href;
  const id = getIdByUrl(url);

  client.prefetchQuery([queryKeys, id], () => {
    return getUserInfo(id).then((data) => formatUserData(data));
  });
};

function getIdByUrl(url: string) {
  const startIdIdx = url.lastIndexOf('/');
  const regExp = new RegExp(/(\/[a-z0-9-]+[a-z0-9])$/i);
  if (startIdIdx !== -1 && regExp.test(url)) {
    return url.substring(startIdIdx + 1);
  }
  return '';
}
