import { lazy } from 'react';
import {
  selfiePay,
  selfiePaySuccess,
  selfiePayFailed
} from '@root/constants/paths';
import { formPayData, getSelfieContentData } from '@root/constants/formData';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import CardInfo from '@comp/CardInfo';

const { main } = getSelfieContentData();

const CustomMain = lazy(() => import('@comp/CustomMain'));

export const getSelfiePayRoutes = () => [
  {
    path: selfiePay(),
    element: (
      <CustomMain
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: selfiePaySuccess,
          failed: selfiePayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </CustomMain>
    )
  },
  {
    path: selfiePaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: selfiePayFailed(),
    element: <CustomFailed redirectPath={selfiePay} />
  }
];
