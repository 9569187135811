import { SendPayPost } from '@root/api/getUserInfo';

export const getFakeForm = (data: SendPayPost) => {
  const { url, ...rest } = data;
  const form = document.createElement('form');
  document.body.appendChild(form);
  form.method = 'POST';
  form.action = url;
  Object.entries(rest).forEach((field) => {
    const [name, value] = field;
    if (value) {
      createInputFormFields(form, name, value);
    }
  });
  form.submit();
  form.remove();
};

function createInputFormFields(
  form: HTMLFormElement,
  name: string,
  value: string
) {
  const element = document.createElement('INPUT') as HTMLInputElement;
  element.name = name;
  element.value = value;
  element.type = 'hidden';
  form.appendChild(element);
}
