import { lazy } from 'react';
import { slonPay, slonPayFailed, slonPaySuccess } from '@root/constants/paths';
import { formPayData, getSlonContentData } from '@root/constants/formData';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import CardInfo from '@comp/CardInfo';

const { main } = getSlonContentData();
const CustomMain = lazy(() => import('@comp/CustomMain'));

export const getSlonPayRoutes = () => [
  {
    path: slonPay(),
    element: (
      <CustomMain
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: slonPaySuccess,
          failed: slonPayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </CustomMain>
    )
  },
  {
    path: slonPaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: slonPayFailed(),
    element: <CustomFailed redirectPath={slonPay} />
  }
];
