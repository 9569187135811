import { useState, useEffect } from 'react';
import {
  getCookieAndLocal,
  setCookieAndLocal
} from '@root/utils/handelCookieAndLocal';
import { load as fingerprintLoad } from '@fingerprintjs/fingerprintjs';

const FINGER_PRINT_KEY = '__FP';

export const useFingerPrint = () => {
  const [fingerPrint, setFingerPrint] = useState(
    getCookieAndLocal(FINGER_PRINT_KEY) || ''
  );

  useEffect(() => {
    if (fingerPrint) return;
    const getFingerPrint = async () => {
      try {
        const fpPromise = await fingerprintLoad();
        const fp = await fpPromise;
        const result = await fp.get();
        setCookieAndLocal(FINGER_PRINT_KEY, result.visitorId);
        setFingerPrint(result.visitorId);
      } catch (error) {
        console.log(error);
      }
    };

    getFingerPrint();
  }, [fingerPrint]);

  return { fingerPrint };
};
