import { lazy } from 'react';
import {
  slonVerify,
  slonVerifyFailed,
  slonVerifySuccess
} from '@root/constants/paths';
import { formVerifyData, getSlonContentData } from '@root/constants/formData';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';

const { main } = getSlonContentData();
const CustomMain = lazy(() => import('@comp/CustomMain'));

export const getSlonVerifyRoutes = () => [
  {
    path: slonVerify(),
    element: (
      <CustomMain
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: slonVerifySuccess,
          failed: slonVerifyFailed
        }}
      />
    )
  },
  {
    path: slonVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: slonVerifyFailed(),
    element: <CustomFailed redirectPath={slonVerify} />
  }
];
