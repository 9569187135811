export const SLON_MERCHANT = '4';

export const slonVerify = (param?: string) => {
  if (param) {
    return `/${SLON_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const slonVerifySuccess = (param?: string) => {
  if (param) {
    return `/${SLON_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const slonVerifyFailed = (param?: string) => {
  if (param) {
    return `/${SLON_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const slonPay = (param?: string) => {
  if (param) {
    return `/${SLON_MERCHANT}/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const slonPaySuccess = (param?: string) => {
  if (param) {
    return `/${SLON_MERCHANT}/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const slonPayFailed = (param?: string) => {
  if (param) {
    return `/${SLON_MERCHANT}/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};
