import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorRedirectProps {
  errorInfo: {
    status: number;
    message: string;
    url: string;
  } | null;
  classBtn: string;
}

const ErrorRedirect: React.FC<ErrorRedirectProps> = ({
  errorInfo,
  classBtn
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (errorInfo?.status === 417) {
      const timer = setTimeout(() => {
        if (errorInfo.url) {
          window.location.href = errorInfo.url;
        }
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [errorInfo]);

  if (errorInfo?.status === 417 || errorInfo?.status === 409) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            marginTop: '100px',
            justifyContent: 'center',
            padding: '0 50px',
            textAlign: 'center'
          }}
        >
          <div>
            {errorInfo?.status === 417
              ? t('common:session_time')
              : t('common:session_time_409')}
          </div>
        </div>
        <div style={{ margin: '30px' }}>
          <button className={classBtn}>
            <a href={errorInfo.url} style={{ color: 'white' }}>
              {t('common:back_to_site')}
            </a>
          </button>
        </div>
      </>
    );
  }

  return null;
};

export default ErrorRedirect;
