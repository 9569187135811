import { useTranslation } from 'react-i18next';

const CvvInfo = () => {
  const { t } = useTranslation(['common']);

  return (
    <div className="info">
      <span className="symbol">i</span>
      <div className="window">
        <p>{t('common:code_3_digits')}</p>
        <svg
          width="48"
          height="29"
          viewBox="0 0 48 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.000366211"
            width="47.9997"
            height="28.9395"
            rx="3.22222"
            fill="#C4C4C4"
          />
          <rect y="4.82373" width="47.9997" height="6.43101" fill="#353535" />
          <rect
            x="2.40039"
            y="13.2632"
            width="31.1998"
            height="6.02907"
            rx="1.20833"
            fill="white"
          />
          <path
            d="M24.255 17.5159C23.8821 17.9478 23.3744 18.1638 22.732 18.1638C22.1228 18.1638 21.6188 17.9718 21.22 17.5878C20.8213 17.2002 20.6219 16.7017 20.6219 16.0925C20.6219 15.4759 20.8231 14.9794 21.2256 14.6028C21.6317 14.2225 22.1394 14.0323 22.7486 14.0323C23.0329 14.0323 23.3079 14.084 23.5738 14.1874C23.8433 14.2871 24.0574 14.4274 24.2162 14.6083L23.5738 15.2507C23.4889 15.1326 23.3744 15.044 23.2304 14.9849C23.0901 14.9221 22.9406 14.8907 22.7818 14.8907C22.4532 14.8907 22.1818 15.0052 21.9677 15.2341C21.7572 15.4593 21.652 15.7455 21.652 16.0925C21.652 16.447 21.7554 16.7368 21.9622 16.962C22.1689 17.1836 22.4366 17.2943 22.7652 17.2943C23.1307 17.2943 23.4076 17.1614 23.5959 16.8956L24.255 17.5159ZM28.1167 14.1376L26.6048 18.0586H25.6577L24.1735 14.1376H25.2368L26.1451 16.9177H26.1673L27.07 14.1376H28.1167ZM31.8918 14.1376L30.3798 18.0586H29.4328L27.9486 14.1376H29.0119L29.9202 16.9177H29.9423L30.845 14.1376H31.8918Z"
            fill="#FF8900"
          />
        </svg>
      </div>
    </div>
  );
};

export default CvvInfo;
