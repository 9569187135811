import { excludeValidationForSomeCards } from '@root/utils/validation';

export const lunaValidation = (card: string | undefined) => {
  if (!card) return true;
  if (excludeValidationForSomeCards(card)) return true;
  if (card.indexOf('*') !== -1) return true;
  if (card[0] === '0') return false;
  // Здесь храним контрольную сумму
  let checksum = 0;
  // Переводим номер карточки из строки в массив чисел
  // eslint-disable-next-line newline-per-chained-call
  const cardnumbers = card.replace(/\s/g, '').split('').map(Number);

  // Проходимся по каждому числу
  for (const [index, num] of cardnumbers.entries()) {
    // Если index чётный, значит число стоит на нечётной позиции
    // Так получается потому что считаем с нуля
    if (index % 2 === 0) {
      const buffer = num * 2;
      // Если удвоенное число больше 9, то вычитаем из него 9 и прибавляем к контрольной сумме
      // Если нет, то сразу прибавляем к контрольной сумме
      buffer > 9 ? (checksum += buffer - 9) : (checksum += buffer);
    }
    // Если число стоит на чётной позиции, то прибавляем его к контрольной сумме
    else {
      checksum += num;
    }
  }
  // Если контрольная сумма делится без остатка на 10, то номер карты правильный
  return checksum % 10 === 0 ? true : false;
};
