import { customLookupPath } from '@root/constants/paths';
import CustomLookupForm from '@comp/CustomLookup';
import { formPayData, getSelfieContentData } from '@root/constants/formData';

const { main } = getSelfieContentData();

export const getLookupRoute = () => [
  {
    path: customLookupPath(),
    element: <CustomLookupForm formData={formPayData} mainContent={main} />
  }
];
