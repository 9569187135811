import './CommitionWarning.scss';
import { useTranslation } from 'react-i18next';

const CommitionWarning = (props: { text?: string }) => {
  const { text } = props;
  const { t } = useTranslation(['common']);

  return (
    <p className="commition-warning" style={{ marginBottom: '5px' }}>
      {text || t('common:commission_for_translation_services')}
    </p>
  );
};

export default CommitionWarning;
