import Cookies from 'js-cookie';

export const getCookieAndLocal = (key: string) => {
  const savedValue = localStorage.getItem(key) || Cookies.get(key) || null;

  return savedValue;
};

export const setCookieAndLocal = (key: string, value: string) => {
  if (!key || !value) return;
  localStorage.setItem(key, value);
  Cookies.set(key, value);
};
