import { ChangeEvent } from 'react';
import { Controller, Control } from 'react-hook-form';

type RawFieldsProps = {
  name: string;
  control: Control<any>;
  type?: 'text' | 'number';
  parseValue?: (value: string) => string;
  maxLength?: number;
  inputMode?: 'text' | 'numeric' | 'email';
  classNames?: {
    error?: string;
  };
  placeholder?: string;
};

const RawFields = (props: RawFieldsProps) => {
  const {
    name,
    control,
    type = 'text',
    parseValue,
    maxLength,
    inputMode = 'text',
    classNames,
    placeholder
  } = props;

  const getErrorStyle = () =>
    classNames && classNames.error ? classNames.error : 'error-text';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
        const onChangeHandler = (evt: ChangeEvent<HTMLInputElement>) => {
          const value = evt.target.value;
          if (parseValue) {
            return onChange(parseValue(value));
          }
          return onChange(evt);
        };
        return (
          <>
            <input
              value={value}
              onChange={onChangeHandler}
              name={name}
              type={type}
              maxLength={maxLength}
              inputMode={inputMode}
              placeholder={placeholder}
            />
            {error && <p className={getErrorStyle()}>{error.message}</p>}
          </>
        );
      }}
    />
  );
};

export default RawFields;
