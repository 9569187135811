import sf_form_ru from './ru/sf-form.json';
import commonRu from './ru/common.json';
import errorRu from './ru/error.json';

import sf_form_uk from './uk/sf-form.json';
import commonUk from './uk/common.json';
import errorUk from './uk/error.json';

export const locates = {
  ru: {
    sf_form: sf_form_ru,
    common: commonRu,
    error: errorRu
  },
  uk: {
    sf_form: sf_form_uk,
    common: commonUk,
    error: errorUk
  }
};
