import { lazy } from 'react';
import { getSlonVerifyRoutes } from './slonVerifyRoutes';
import { getSlonPayRoutes } from './slonPayRoutes';
import { SLON_MERCHANT } from '@root/constants/paths';
import { getSlonContentData } from '@root/constants/formData';
import { getLookupRoute } from './getLookupRoute';

const CustomLayout = lazy(() => import('@root/components/CustomLayout'));

const { header, footer } = getSlonContentData();

export const slonRoutes = {
  path: SLON_MERCHANT,
  element: <CustomLayout headerContent={header} footerContent={footer} />,
  children: [
    ...getSlonVerifyRoutes(),
    ...getSlonPayRoutes(),
    ...getLookupRoute()
  ]
};
