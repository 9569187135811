import classNames from 'classnames';
import { Controller, Control, FieldError } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

type CustomNumberFieldsProps = {
  label: string;
  error: FieldError | undefined;
  control: Control<any>;
  name: string;
};

const CustomNumberFields = (props: CustomNumberFieldsProps) => {
  const { label, error, name, control } = props;
  return (
    <div
      className={classNames('input-box ', {
        invalid: Boolean(error)
      })}
    >
      <label htmlFor="">{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, name }, fieldState }) => (
          <>
            <NumericFormat
              value={value}
              onChange={onChange}
              name={name}
              decimalScale={2}
            />
            {fieldState.error && (
              <p className="error-text">{fieldState.error.message}</p>
            )}
          </>
        )}
      />
    </div>
  );
};

export default CustomNumberFields;
