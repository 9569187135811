// @ts-nocheck
export async function handelPaymentRequest(request: PaymentRequest) {
  try {
    request.onmerchantvalidation = (event) => {
      // Call your own server to request a new merchant session.
      const merchantSessionPromise = fetch(
        'https://api.paytech.com.ua/apple-pay/merchant'
      )
        .then((res) => res.json())
        .then((merchantSession) => merchantSession);

      event.complete(merchantSessionPromise);
    };

    const response = await request.show();
    const status = 'success';
    await response.complete(status);
  } catch (e) {
    console.log(e);
  }
}
