import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessIcon } from '@root/images/custom/success.svg';
import { ReactComponent as ErrorIcon } from '@root/images/custom/error-icon.svg';
import { useSearchParams } from 'react-router-dom';
import {
  getTransactionStatus,
  TranStatusResponse
} from '@root/api/getUserInfo';
import { getFormattedPriceLocale } from '@root/utils/convertors';
import LoaderComponent from '@comp/LoaderComponet';
import { useCheckContext } from '~/context/CheckContext';

const Status = () => {
  const { state, setCheckData, setIsCheck, setTranId } = useCheckContext();
  const { t } = useTranslation(['common']);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('tranId');
  const [data, setData] = useState<TranStatusResponse | null>(null);

  useEffect(() => {
    if (state.checkData || state.tranId) {
      setIsCheck(false);
      setCheckData(null);
      setTranId(null);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getTransactionStatus(id).then((data) => setData(data));
    }
  }, [id]);

  const getTitleComponent = (data: TranStatusResponse) => {
    if (data.statusCode === '1000') {
      return {
        title: (
          <div className="status-title-container">
            <div className="icon-cotainer">
              <SuccessIcon />
            </div>
            <h1 className="status-title">{data.codeDesc}</h1>
          </div>
        ),
        content: (
          <div className="cardProof__list-row cardProof__list-row--huge-pd">
            <p className="cardProof__list-item">Оплачено</p>
            <p className="cardProof__list-item">
              {getFormattedPriceLocale(data.amount)}
            </p>
          </div>
        )
      };
    }

    const errorInfo =
      data.statusDescription !== null && data.statusDescription.split('\n');

    return {
      title: (
        <div className="status-title-container">
          <div className="icon-cotainer">
            <ErrorIcon />
          </div>
          <h1 className="status-title">{data.codeDesc}</h1>
        </div>
      ),
      content: errorInfo ? (
        <ul className="status-error-list">
          {errorInfo.map((info) => (
            <li
              key={info}
              className={`status-error-list__item ${
                errorInfo.length === 1
                  ? 'status-error-list__item--only-one'
                  : ''
              }`.trim()}
            >
              {info}
            </li>
          ))}
        </ul>
      ) : null
    };
  };

  if (data) {
    return (
      <section className="cardProof">
        <form
          className="container-s container-s--min-pd"
          onSubmit={(evt) => {
            evt.preventDefault();
            window.location.replace(data.merchantLink);
          }}
        >
          {getTitleComponent(data).title}
          <div className="cardProof__list cardProof__list--no_border">
            {getTitleComponent(data).content}
          </div>
          <button className="btn" type="submit">
            {t('common:back_to_site')}
          </button>
          {data.statusCode === '1000' && (
            <a
              href={data.receiptLink}
              className="link"
              download
              target="_blank"
              rel="noreferrer"
            >
              {t('common:get_receipt')}
            </a>
          )}
        </form>
      </section>
    );
  }

  return <LoaderComponent />;
};

export default Status;
