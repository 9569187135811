import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';

type ButtonWithSpinProps = {
  disabled: boolean;
  classesNames?: Record<string, string>;
  buttonText?: string;
};

const ButtonWithSpin = (props: ButtonWithSpinProps) => {
  const { disabled, classesNames, buttonText } = props;
  const { t } = useTranslation(['common']);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleClick = () => {
    setIsFormSubmitted(true);
  };
  const buttonClassName = `btn ${
    classesNames && classesNames.btn ? classesNames.btn : ''
  } ${disabled ? 'btn-disabled' : ''}`;
  return (
    <div className="button-spin">
      <button
        disabled={disabled}
        className={buttonClassName}
        type="submit"
        onClick={handleClick}
      >
        {buttonText || t('common:confirm')}
      </button>
      {isFormSubmitted && (
        <Oval wrapperClass="button-spin__spinner" strokeWidth={5} />
      )}
    </div>
  );
};

export default ButtonWithSpin;
