import { lazy } from 'react';
import { CREDIT_PLUS_MERCHANT } from '@root/constants/paths';
import { getCreditPlusPayRoutes } from './creditPlusPayRoutes';
import { getLookupRoute } from './getLookupRoute';

const LayoutCreditPlus = lazy(
  () => import('@pages/creditPlus/LayoutCreditPlus')
);

export const creditPlusRoute = {
  path: CREDIT_PLUS_MERCHANT,
  element: <LayoutCreditPlus />,
  children: [...getCreditPlusPayRoutes(), ...getLookupRoute()]
};
