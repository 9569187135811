import { lazy } from 'react';
import {
  selfieVerify,
  selfieVerifySuccess,
  selfieVerifyFailed
} from '@root/constants/paths';
import { formVerifyData, getSelfieContentData } from '@root/constants/formData';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';

const { main } = getSelfieContentData();
const CustomMain = lazy(() => import('@comp/CustomMain'));

export const getSelfieVerifyRoutes = () => [
  {
    path: selfieVerify(),
    element: (
      <CustomMain
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: selfieVerifySuccess,
          failed: selfieVerifyFailed
        }}
      />
    )
  },
  {
    path: selfieVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: selfieVerifyFailed(),
    element: <CustomFailed redirectPath={selfieVerify} />
  }
];
