import { Controller, Control, FieldError } from 'react-hook-form';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import React from 'react';

type CustomFieldProps = {
  name: string;
  type?: 'text' | 'password';
  control: Control<any>;
  mask: string | string[];
  label: string;
  comp?: React.ReactNode;
  error: FieldError | undefined;
  disabled?: boolean;
  formatChars?: Record<string, string | RegExp>;
  inputMode?: 'text' | 'numeric' | 'email';
  placeholder?: string;
  maskChar?: string;
};

function CustomField(props: CustomFieldProps) {
  const {
    name,
    control,
    label,
    mask,
    comp,
    error,
    disabled = false,
    formatChars,
    inputMode = 'text',
    type = 'text',
    placeholder = '',
    maskChar = '_'
  } = props;
  return (
    <div
      className={classNames('input-box ', {
        invalid: Boolean(error)
      })}
    >
      <label htmlFor="">
        {label}
        {comp ? comp : null}
      </label>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value, name },
          fieldState: { error }
        }) => (
          <>
            {
              <InputMask
                name={name}
                disabled={disabled}
                placeholder={placeholder}
                mask={mask}
                type={type}
                value={value}
                maskChar={maskChar}
                formatChars={
                  formatChars ? formatChars : { 9: '[0-9]', '*': /[0-9*]/ }
                }
                onChange={onChange}
                autoComplete="on"
                id={name}
                inputMode={inputMode}
              >
                {
                  // @ts-ignore
                  (inputProps) => (
                    <input {...inputProps} className="cs-input-box__input" />
                  )
                }
              </InputMask>
            }
            {error && <p className="error-text">{error.message}</p>}
          </>
        )}
      />
    </div>
  );
}

export default CustomField;
