import { sendLookupCode } from '@root/api/getUserInfo';
import { LookupFormInputs } from '@comp/CustomLookup';

export const getLookupSubmitHandler = (id: string | undefined) => {
  const lookupSubmitHandler = (values: LookupFormInputs) => {
    if (id)
      sendLookupCode(id, values).then((res) => {
        window.location.replace(res.url);
      });
  };

  return { lookupSubmitHandler };
};
