import ApplePayButton from '@comp/ApplePayButton';
import { OnSubmit } from '@hooks/useOnSubmit';
import GooglePayButtonComponent from '../GooglePayButton';
import './PayButtons.scss';

export type PayInfo = {
  fee: string;
  clientName: string | undefined;
  agreementId: number | undefined;
  fullAmount: string;
  payAmount: string;
};

type PayButtonsProps = {
  payInfo: PayInfo;
  isApple: boolean;
  isGoogle: boolean;
  onSubmit: OnSubmit;
};

const PayButtons = (props: PayButtonsProps) => {
  const { isApple, isGoogle, onSubmit, payInfo } = props;

  return (
    <div className="button-container">
      <div className="childOne">
        {isApple && <ApplePayButton payInfo={payInfo} onSubmit={onSubmit} />}
      </div>
      <div className="childTwo">
        {isGoogle && (
          <GooglePayButtonComponent payInfo={payInfo} onSubmit={onSubmit} />
        )}
      </div>
    </div>
  );
};

export default PayButtons;
