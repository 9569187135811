export const isDisabledCardField = (
  cardMask: string | undefined,
  editCardMask: boolean
) => {
  if (cardMask && !editCardMask) {
    return true;
  }
  return false;
};

export const isDisabledExpiryField = (
  expiry: string | undefined,
  editCardMask: boolean
) => {
  if (expiry && !editCardMask) {
    return true;
  }
  return false;
};
