import { UserData } from '@root/utils/formatUserData';
import style from '@root/scss/creditPlus/index.module.scss';

type CardInfoCreditPlusProps = {
  userData: UserData | null;
};

const CardInfoCreditPlus = (props: CardInfoCreditPlusProps) => {
  const { userData } = props;

  if (userData) {
    const {
      fee,
      options: { client_name, extTerm },
      payAmount,
      fullAmount
    } = userData;
    return (
      <div className={style.blockForm__top}>
        <div className={style.row}>
          <p>Клієнт</p>
          <b>{client_name}</b>
        </div>
        <div className={style.row}>
          <p>Сума платежу</p>
          <b>{payAmount} грн</b>
        </div>
        {extTerm ? (
          <div className={style.row}>
            <p>Термін продовження</p>
            <b>{`${extTerm || 0} днів`}</b>
          </div>
        ) : null}
        {fee ? (
          <div className={style.row}>
            <p>Комісія*</p>
            <b>{fee} грн</b>
          </div>
        ) : null}
        {fullAmount ? (
          <div className={style.row}>
            <p>Всього до сплати**</p>
            <b>{fullAmount} грн</b>
          </div>
        ) : null}
      </div>
    );
  }

  return null;
};

export default CardInfoCreditPlus;
