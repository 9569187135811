import style from '@root/scss/credit7/index.module.scss';
import { useTranslation } from 'react-i18next';

const verifyRulls = [
  'is_it_your_card_ua',
  'is_active_card',
  'is_available_net_transactions'
];

const VerifyInfoK7 = () => {
  const { t } = useTranslation('common');
  return (
    <div className={style['verify-rull']}>
      <p className={style['verify-rull-text']}>{t('common:verify_rules')}</p>
      <ul className={style['verify-rull-list']}>
        {verifyRulls.map((rull) => (
          <li key={rull} className={style['verify-rull-list__item']}>
            {t(`common:${rull}`)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VerifyInfoK7;
