import './ModalContainer.scss';

type ModalContainerProps = {
  children: React.ReactNode;
};

const ModalContainer = (props: ModalContainerProps) => {
  const { children } = props;

  return (
    <div className="modal-container">
      <div className="modal-content">{children}</div>
    </div>
  );
};

export default ModalContainer;
