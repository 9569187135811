import CustomAnonForm from '@comp/CustomAnonForm/Index';
import { ContentData } from '@root/constants/formData/types';
import { OnSubmitParams, useOnSubmit } from '@root/hooks/useOnSubmit';
import style from '@root/scss/credit7/index.module.scss';
import { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { instanceApi } from '~/api';

type CustomAnonProps = {
  mainContent: ContentData['main'];
  formData: {
    title: string;
  };
  redirectPaths: OnSubmitParams['redirectPaths'];
};

export type UserDataAnon = {
  isApple: boolean;
  isGoogle: boolean;
};

const CustomAnon = (props: CustomAnonProps) => {
  const { redirectPaths, formData, mainContent } = props;
  const { onSubmit } = useOnSubmit({ redirectPaths });
  const [userDataAnon, setUserDataAnon] = useState<UserDataAnon>();
  const fetchUserData = async () => {
    instanceApi
      .get<UserDataAnon>(`/frame/frontend/details/anon`, {
        method: 'GET'
      })
      .then((data) => {
        setUserDataAnon(data.data);
        return data.data;
      });
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  if (userDataAnon) {
    return (
      <>
        <div>
          <CustomAnonForm
            onSubmit={onSubmit}
            formData={formData}
            mainContent={mainContent}
            userData={userDataAnon}
          />
        </div>
      </>
    );
  }

  return (
    <section className={style.blockForm}>
      <div className={style.container_540} style={{ marginTop: '105px' }}>
        <Oval wrapperClass={style['centred-block']} />
      </div>
    </section>
  );
};

export default CustomAnon;
