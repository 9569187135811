import { lazy } from 'react';
import { customLookupPath } from '@root/constants/paths';

const LookupKpluseForm = lazy(
  () => import('@pages/creditPlus/LookupKpluseForm')
);

export const getLookupRoute = () => [
  {
    path: customLookupPath(),
    element: <LookupKpluseForm />
  }
];
