export const getFormattedPriceLocale = (price: any) =>
  `${new Intl.NumberFormat('ru', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price)} грн`;

export const formatSendCardNuber = (cardNumber: string) =>
  cardNumber.split(' ').join('');

export const convertCardMask = (mask: string) => {
  if (!mask) return mask;
  const maskArr = [];
  let start = 0;
  let end = 4;
  while (maskArr.length !== 4) {
    maskArr.push(mask.substring(start, end));
    start = end;
    end = start + 4;
  }
  return maskArr.join(' ');
};

export const formatSendExpiry = (expiry: string) => expiry.split('/').join('');

export const converExpiry = (expiry: string | undefined) => {
  if (!expiry || expiry.length < 4) return '';
  const maskArr = [];
  let start = 0;
  let end = 2;
  while (maskArr.length !== 2) {
    maskArr.push(expiry.substring(start, end));
    start = end;
    end = start + 2;
  }
  return maskArr.join('/');
};
