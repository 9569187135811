import { app } from '@root/env';
import { statusResposeSuccess } from '@root/fakeApiConst/statusResponse';
import qs from 'qs';
import { instanceApi } from './index';

const { isFakeApi } = app;

function getFormData<DataType>(data: DataType) {
  return qs.stringify(data);
}

export type UserInfoOptions = {
  agreement_id: number;
  client_name: string;
  merchant: string;
  isExtension: boolean;
  isRestructure: boolean;
  extTerm?: number;
  pageTitle?: 'partPay' | 'extension' | 'pay';
  buttonTitle?: 'partPay' | 'extension' | 'pay';
  receipt_email?: string;
  document?: string;
};

export type GetUserInfo = {
  amount: number;
  csrf: string;
  description: string;
  editCardMask: boolean;
  fee: number;
  fullAmount: number;
  lang: 'ru' | 'uk';
  pan?: string;
  tranId: number;
  uuid: string;
  options: UserInfoOptions;
  apayEnabled: boolean;
  gpayEnabled: boolean;
  isChecked: boolean;
  expiry?: string;
  token?: string;
};

export const getUserInfo = (uuid: string) => {
  if (!uuid) {
    return Promise.reject({ response: { status: 'UUID is required.' } });
  }

  return instanceApi
    .get<GetUserInfo>(`/frame/frontend/details/${uuid}`, {
      method: 'GET'
    })
    .then((data) => data.data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export type SendPayParams = {
  session: string;
  merchant: string;
  card_number: string;
  expiry: string;
  cvv: string;
  save: string;
  email?: string;
  payer?: string;
  amount?: string;
  token?: string;
  applePayToken?: string;
  client_name?: string;
  inn?: string;
  date_bd?: string;
  googlePayToken?: string;
};

export type SendPayPost = {
  url: string;
  creq?: string;
  threeDSSessionData?: string;
  pareq?: string;
};

export const sendPay = async (data: SendPayParams, fingerPrint = '') => {
  const { card_number, ...rest } = data;
  const formData = getFormData({ ...rest, numbercard: card_number });

  return instanceApi
    .post<SendPayPost>('/pay/v2', formData, {
      headers: {
        fingerPrint,
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((data) => data.data);
};

export const sendUserEncodeData = (
  id: string,
  data: { frame: string },
  fingerPrint = ''
) =>
  instanceApi.post(`/frame/frontend/details/status/${id}`, data, {
    headers: {
      fingerPrint
    }
  });

export const sendLookupCode = (id: string, data: { lookupCode: string }) =>
  instanceApi
    .post<{ url: string }>(`frame/complete/lookup/${id}`, data)
    .then((data) => data.data);

export type TranStatusResponse = {
  statusCode: string;
  codeDesc: string;
  receiptLink: string;
  merchantLink: string;
  statusDescription: null | string;
  amount: number;
  isAutomaticRedirect?: boolean;
};

export const getTransactionStatus = (id: string) =>
  instanceApi
    .get<TranStatusResponse>(`/frame/frontend/status/${id}`)
    .then((response) => {
      const data = response.data;
      if (data.isAutomaticRedirect && data.merchantLink.length > 0) {
        setTimeout(() => {
          window.location.href = data.merchantLink;
        }, 2000);
      }

      return data;
    })
    .catch((err) => {
      if (isFakeApi) return statusResposeSuccess;
      throw err;
    });

type CheckinSanctionList = {
  client_name: string;
  document?: string;
  titleUk?: string;
  titleEn?: string;
  titleRu?: string;
  date_bd?: string;
  inn?: string;
};

export function checkinSanctionList(data: CheckinSanctionList) {
  return instanceApi
    .post<{ checkedName: string; isCheckPassed: boolean }>(
      `/api/sanction/check`,
      {
        ...data
      }
    )
    .then((data) => data.data);
}
