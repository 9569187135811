import { useState, createContext, useContext } from 'react';

type CheckContextType = {
  state: {
    isCheck: boolean;
    tranId: null | string;
    checkData: null | {
      client_name: string;
      inn: string;
      date_bd: string;
    };
  };
  setIsCheck: (state: boolean) => void;
  setCheckData: (
    checkData: Exclude<CheckContextType, null>['state']['checkData']
  ) => void;
  setTranId: (tranId: null | string) => void;
} | null;

const CheckContext = createContext<CheckContextType>(null);

type CheckProviderProp = {
  children: React.ReactNode;
};

const CheckProvider = (props: CheckProviderProp) => {
  const { children } = props;

  const [state, setState] = useState<Exclude<CheckContextType, null>['state']>({
    isCheck: false,
    tranId: null,
    checkData: null
  });

  return (
    <CheckContext.Provider
      value={{
        state,
        setIsCheck: (isCheck) => {
          setState((prev) => ({ ...prev, isCheck }));
        },
        setCheckData: (checkData) => {
          setState((prev) => ({ ...prev, checkData }));
        },
        setTranId: (tranId) => {
          setState((prev) => ({ ...prev, tranId }));
        }
      }}
    >
      {children}
    </CheckContext.Provider>
  );
};

export default CheckProvider;

export const useCheckContext = () => {
  const context = useContext(CheckContext);

  if (!context) {
    throw new Error('Must be use inside CheckProvider!!!');
  }

  return context;
};
