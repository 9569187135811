import { lazy } from 'react';
import {
  customPay,
  customPayFailed,
  customPaySuccess
} from '@root/constants/paths';
import { formPayData, getPaytechContentData } from '@root/constants/formData';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import CardInfo from '@comp/CardInfo';

const CustomMain = lazy(() => import('@comp/CustomMain'));
const { main } = getPaytechContentData();

export const getCustomPayRoutes = () => [
  {
    path: customPay(),
    element: (
      <CustomMain
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: customPaySuccess,
          failed: customPayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </CustomMain>
    )
  },
  {
    path: customPaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: customPayFailed(),
    element: <CustomFailed redirectPath={customPay} />
  }
];
