import { UserData } from '~/utils/formatUserData';

interface PaymentDetailsProps {
  userData: UserData;
}
export const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  userData
}: PaymentDetailsProps) => {
  return (
    <div className="payment-details-container-starfin">
      <div className="payment-details-box-starfin">
        <div className="payment-details-row-starfin">
          <span className="payment-details-label-starfin">Призначення:</span>
          <span className="payment-details-value-starfin">
            {userData.description}
          </span>
        </div>
        <div className="payment-details-row row-bg-starfin">
          <span className="payment-details-label-starfin">
            Сума без комісії:
          </span>
          <span className="payment-details-value-starfin">
            {userData.payAmount}
          </span>
        </div>
        <div className="payment-details-row-starfin">
          <span className="payment-details-label-starfin">
            Комісія складатиме:
          </span>
          <span className="payment-details-value-starfin">{userData.fee}</span>
        </div>
        <div className="payment-details-row row-bg-starfin">
          <span className="payment-details-label-starfin">
            Разом до оплати:
          </span>
          <span className="payment-details-value-starfin">
            {userData.fullAmount}
          </span>
        </div>
      </div>
    </div>
  );
};
