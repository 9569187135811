import { lazy } from 'react';
import VerifyInfoK7 from '@pages/credit7/VerifyInfoK7';
import {
  credit7Verify,
  credit7VerifySuccess,
  credit7VerifyFailed
} from '@root/constants/paths';
import {
  formVerifyData,
  getCredit7ContentData
} from '@root/constants/formData';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';

const VerifyK7 = lazy(() => import('@pages/credit7/VerifyK7'));

const { main } = getCredit7ContentData();

export const getCredit7VerifyRoutes = () => [
  {
    path: credit7Verify(),
    element: (
      <VerifyK7
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: credit7VerifySuccess,
          failed: credit7VerifyFailed
        }}
      >
        <VerifyInfoK7 />
      </VerifyK7>
    )
  },
  {
    path: credit7VerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: credit7VerifyFailed(),
    element: <CustomFailed redirectPath={credit7Verify} />
  }
];
