import style from '@root/scss/credit7/index.module.scss';
import { useForm } from 'react-hook-form';
import { K7Field } from './K7Field';
import iconpay1 from '@root/images/k7icons/logo_ico_1.svg';
import iconpay2 from '@root/images/k7icons/logo_ico_2.svg';
import iconpay3 from '@root/images/k7icons/logo_ico_3.svg';
import iconpay4 from '@root/images/k7icons/logo_ico_4.svg';
import { Oval } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import UserAgreement from '@comp/UserAgreement';
import LogoBlock from './LogoBlock';
import { getLookupSubmitHandler } from '@root/handlers';
import { useParams } from 'react-router-dom';
import { getValidationResolver } from '@root/utils/validation';
import * as yup from 'yup';

export type LookupK7FormInputs = {
  lookupCode: string;
};

export const LookupK7Form = () => {
  const { id } = useParams();
  const { lookupSubmitHandler } = getLookupSubmitHandler(id);
  const { t } = useTranslation(['common']);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = useForm<LookupK7FormInputs>({
    resolver: getValidationResolver({
      lookupCode: yup
        .string()
        .required(t('error:fill_fields'))
        .matches(/^\d{6}$/, t('error:not_valid_lookup_code'))
    }),
    defaultValues: {
      lookupCode: ''
    }
  });

  return (
    <section className={style.blockForm}>
      <form
        className={style.container_540}
        onSubmit={handleSubmit(lookupSubmitHandler)}
      >
        <h1>Погашення кредиту</h1>
        <div className={style.blockForm__form}>
          <K7Field
            control={control}
            name="lookupCode"
            label={t('common:lookup_code')}
            mask={'99999999'}
            error={errors['lookupCode']}
          />
        </div>
        <div className="button-spin">
          <button
            className={`${style.btn} ${style.btn__purple}`}
            disabled={isSubmitting}
            type="submit"
          >
            {t('common:confirm')}
          </button>
          {isSubmitting && (
            <Oval
              wrapperClass="button-spin__spinner"
              strokeWidth={5}
              color="#fff"
            />
          )}
        </div>
        <UserAgreement />
        <LogoBlock imgs={[iconpay1, iconpay2, iconpay3, iconpay4]} />
      </form>
    </section>
  );
};
