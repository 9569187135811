import { lazy } from 'react';
import { CUSTOM_MERCHANT } from '@root/constants/paths';
import { getPaytechContentData } from '@root/constants/formData';
import { getCustomVerifyRoutes } from './customVerify';
import { getCustomPayRoutes } from './customPayRoutes';
import { getCustomLookupRoute } from './customLookupRoute';
import { getCustomAnonRoutes } from './getCustomAnonRoutes';
import { getCustomQrRoute } from './getQrRoute';

const CustomLayout = lazy(() => import('@root/components/CustomLayout'));

const { header, footer } = getPaytechContentData();

export const customRoutes = {
  path: CUSTOM_MERCHANT,
  element: <CustomLayout headerContent={header} footerContent={footer} />,
  children: [
    ...getCustomVerifyRoutes(),
    ...getCustomLookupRoute(),
    ...getCustomPayRoutes(),
    ...getCustomAnonRoutes(),
    ...getCustomQrRoute()
  ]
};
