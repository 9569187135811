import { lazy } from 'react';
import CustomFailed from '~/components/CustomFailed';
import CustomSuccess from '~/components/CustomSuccess';
import {
  STARFIN_MERCHANT,
  starFinPay,
  starFinPayFailed,
  starFinPaySuccess,
  starFinVerifyFailed,
  starFinVerifySuccess
} from '~/constants/paths/pathStarfin';
import { StarFinMain } from '~/pages/Starfin/StarFinMain';

const LayoutStarFinCredit = lazy(() => import('~/pages/Starfin/index'));
export const starFinRoute = {
  path: STARFIN_MERCHANT,
  element: <LayoutStarFinCredit />,
  children: [
    {
      path: 'verify/:id',
      element: (
        <StarFinMain
          redirectPaths={{
            success: starFinVerifySuccess,
            failed: starFinVerifyFailed
          }}
        />
      )
    },
    {
      path: 'pay/:id',
      element: (
        <StarFinMain
          redirectPaths={{
            success: starFinPaySuccess,
            failed: starFinPayFailed
          }}
        />
      )
    },
    {
      path: starFinPaySuccess(),
      element: <CustomSuccess />
    },
    {
      path: starFinPayFailed(),
      element: <CustomFailed redirectPath={starFinPay} />
    }
  ]
};
