import { checkinSanctionList, getUserInfo } from '@root/api/getUserInfo';
import { formatUserData } from '@root/utils/formatUserData';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useCheckContext } from '~/context/CheckContext';
import { queryKeys } from './routesName';

export interface ErrorResponse {
  status: number;
  message: string;
  url: string;
}

export const useUserData = (id: string | undefined) => {
  const { setIsCheck, state, setTranId } = useCheckContext();
  const [errorInfo, setErrorInfo] = useState<ErrorResponse | null>(null);

  const { data: userData = null, error: userError } = useQuery(
    [queryKeys.user_info, id],
    async () => {
      if (id) {
        try {
          const data = await getUserInfo(id);
          return formatUserData(data);
        } catch (error: any) {
          if (error.response) {
            const { status, data } = error.response;
            setErrorInfo({
              status,
              message: data.message,
              url: data.url
            });
          }
          throw error;
        }
      }
      return null;
    },
    {
      enabled: Boolean(id)
    }
  );

  const { data: checkSanction = null } = useQuery(
    [queryKeys.check_in_sanction, id],
    () => {
      return checkinSanctionList({
        client_name: userData?.options.client_name || '',
        document: userData?.options.document || ''
      });
    },
    {
      enabled: Boolean(userData) && Boolean(userData?.options.client_name)
    }
  );

  useEffect(() => {
    if (
      id &&
      checkSanction &&
      !checkSanction.isCheckPassed &&
      state &&
      state.checkData === null
    ) {
      setIsCheck(true);
      setTranId(id);
    }
  }, [checkSanction]);

  return { userData, errorInfo, userError };
};
