export const STARFIN_MERCHANT = '8';

export const starFinVerify = (param?: string) => {
  if (param) {
    return `/${STARFIN_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const starFinVerifySuccess = (param?: string) => {
  if (param) {
    return `/${STARFIN_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const starFinVerifyFailed = (param?: string) => {
  if (param) {
    return `/${STARFIN_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const starFinPay = (param?: string) => {
  if (param) {
    return `/${STARFIN_MERCHANT}/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const starFinPaySuccess = (param?: string) => {
  if (param) {
    return `/${STARFIN_MERCHANT}/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const starFinPayFailed = (param?: string) => {
  if (param) {
    return `/${STARFIN_MERCHANT}/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};
