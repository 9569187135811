import { UserData } from '@root/utils/formatUserData';
import { useTranslation } from 'react-i18next';

type CardInfoProps = {
  userData: UserData | null;
};

const CardInfo = (props: CardInfoProps) => {
  const { userData } = props;

  const { t } = useTranslation(['common']);

  if (userData) {
    const {
      options: { client_name, agreement_id },
      fee,
      fullAmount,
      payAmount
    } = userData;
    return (
      <div className="cardProof__list">
        <div className="cardProof__list-row">
          <p>{t('common:payer')}</p>
          <p>{client_name || ''}</p>
        </div>
        <div className="cardProof__list-row">
          <p>{t('common:service')}</p>
          <p>{agreement_id || 0}</p>
        </div>
        <div className="cardProof__list-row">
          <p>{t('common:pay_amount')}</p>
          <p>{payAmount}</p>
        </div>
        <div className="cardProof__list-row">
          <p>{t('common:commition')}</p>
          <p>{fee}</p>
        </div>
        <div className="cardProof__list-row">
          <p>{t('common:all_to_pay_2stars')}</p>
          <p>{fullAmount}</p>
        </div>
      </div>
    );
  }

  return null;
};

export default CardInfo;
