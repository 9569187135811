import { useState } from 'react';
import * as yup from 'yup';
import { lunaValidation } from '@root/functions/lunaValidation';
import { validateDate } from './validateDate';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

type UserValidation = {
  expiryValidationFunction?: (value: string | undefined) => boolean;
  noValidateExpiry: boolean;
  noValidateCardNumber: boolean;
  validatePayer?: boolean;
  validateFio?: boolean;
  validateAgreementId?: boolean;
  validateAmount?: boolean;
  validateEmail?: boolean;
  validationText?: {
    cardNumber: Record<string, string>;
    expiry: Record<string, string>;
    cvv: Record<string, string>;
  };
};

export const useValidation = (
  values: UserValidation = {
    noValidateExpiry: false,
    noValidateCardNumber: false,
    validatePayer: false,
    validateFio: false,
    validateAgreementId: false,
    validateAmount: false,
    validateEmail: false
  }
) => {
  const [isValidate, setIsValidate] = useState(true);
  const { t } = useTranslation(['error']);
  const { validationText } = values;

  const cardShema = yup
    .string()
    .required(
      validationText
        ? validationText.cardNumber.required
        : t('error:fill_fields')
    )
    .matches(
      /^\d{4} (\d{4}|\d{2}\*{2}) (\d{4}|\*{4}) \d{4}$/,
      validationText
        ? validationText.cardNumber.matches
        : t('error:not_valid_format')
    )
    .matches(
      /^((?!3|9)\d{4}) (\d{4}|\d{2}\*{2}) (\d{4}|\*{4}) \d{4}$/,
      validationText
        ? validationText.cardNumber.matches
        : t('error:not_valid_card')
    )
    .test(
      'lunaValidation',
      validationText
        ? validationText.cardNumber.lunaValidation
        : t('error:not_valid_card'),
      (value) => lunaValidation(value)
    );

  const expiryShema = yup
    .string()
    .required(
      validationText ? validationText.expiry.required : t('error:fill_fields')
    )
    .matches(
      /^\d{2}\/\d{2}$/,
      validationText
        ? validationText.expiry.matches
        : t('error:not_valid_format')
    )
    .test(
      'expiryValidation',
      validationText
        ? validationText.expiry.expiryValidation
        : t('error:not_valid_date'),
      (value) => {
        if (values.expiryValidationFunction) {
          return values.expiryValidationFunction(value);
        }
        return validateDate(value);
      }
    );

  const cvvShema = yup
    .string()
    .required(
      validationText ? validationText.cvv.required : t('error:fill_fields')
    )
    .matches(
      /^\d{3}$/,
      validationText ? validationText.cvv.matches : t('error:not_valid_cvv')
    );

  const payerShema = yup.string().required(t('error:fill_fields'));
  const amountShema = yup.string().required(t('error:fill_fields'));
  const emailShema = yup.string().email(t('error:not_valid_email'));
  const fioShema = yup.string().required(t('error:fill_fields'));
  const agreementIdShema = yup.string().required(t('error:fill_fields'));

  const getValidationShape = () => {
    const validationShape: {
      cvv: typeof cvvShema;
      expiry?: typeof expiryShema;
      card_number?: typeof cardShema;
      payer?: typeof payerShema;
      fio?: typeof fioShema;
      agreement_id?: typeof agreementIdShema;
      amount?: typeof amountShema;
      email?: typeof emailShema;
    } = { cvv: cvvShema };

    if (!values.noValidateExpiry) {
      validationShape.expiry = expiryShema;
    }

    if (!values.noValidateCardNumber) {
      validationShape.card_number = cardShema;
    }

    if (values.validatePayer) {
      validationShape.payer = payerShema;
    }
    if (values.validateFio) {
      validationShape.fio = fioShema;
    }
    if (values.validateFio) {
      validationShape.agreement_id = agreementIdShema;
    }

    if (values.validateAmount) {
      validationShape.amount = amountShema;
    }

    if (values.validateEmail) {
      validationShape.email = emailShema;
    }

    return validationShape;
  };

  const schema = yup.object().shape(getValidationShape());

  const cardValidation = yupResolver(schema);

  return {
    cardValidation: isValidate ? cardValidation : undefined,
    setIsValidate,
    isValidate
  };
};

export const getValidationResolver = (
  shape: Record<string, yup.StringSchema>
) => yupResolver(yup.object().shape(shape));

const excludeNumberCards = [
  '4414414141414141',
  '5747747474747474',
  '4414414141414142',
  '5747747474747475'
];

export const excludeValidationForSomeCards = (
  cardNumber: string | undefined
) => {
  if (!cardNumber) return false;
  const parsedCardNumber = cardNumber.replace(/[\s,_]/g, '');
  if (parsedCardNumber.length < 16) return false;
  return excludeNumberCards.some(
    (cardNumber) => cardNumber === parsedCardNumber
  );
};
