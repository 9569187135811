import { OnSubmit } from '@hooks/useOnSubmit';

type Option = {
  onSubmit: OnSubmit;
};

export const applePayJSHandler = (session: ApplePaySession, option: Option) => {
  // Create ApplePaySession
  const { onSubmit } = option;
  session.onvalidatemerchant = async () => {
    // Call your own server to request a new merchant session.
    fetch('https://api.paytech.com.ua/apple-pay/merchant', { method: 'POST' })
      .then((res) => res.json()) // Parse response as JSON.
      .then((merchantSession) => {
        session.completeMerchantValidation(merchantSession);
      })
      .catch((err) => {
        console.error('Error fetching merchant session', err);
      });
  };

  session.onpaymentauthorized = (event) => {
    try {
      const applePayToken = JSON.stringify(event.payment.token);
      onSubmit({
        applePayToken,
        card_number: '',
        expiry: '',
        cvv: '',
        save: ''
      });
      session.completePayment(ApplePaySession.STATUS_SUCCESS);
    } catch (error) {
      console.log(error);
    }
  };

  session.oncancel = (event) => {
    //@ts-ignore
    console.log(event.target, 'target');
    // Payment cancelled by WebKit
  };
  session.begin();
};
