import { customPayQr } from '@root/constants/paths';
import CustomQr from '@comp/CustomQr';
import qrImg from '@root/images/selfie/qr.png';

export const getCustomQrRoute = () => [
  {
    path: customPayQr(),
    element: <CustomQr qrImg={qrImg} />
  }
];
