import CustomNumberFields from '@comp/CustomNumberFields';
import CustomWrapperFields from '@comp/CustomWrapperFields';
import CvvInfo from '@comp/CvvInfo';
import RawFields from '@comp/RawFields';
import { ContentData } from '@root/constants/formData/types';
import { OnSubmit } from '@root/hooks/useOnSubmit';
import { onlyNumber } from '@root/utils/parseInputValues';
import { useValidation } from '@root/utils/validation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
import { UserDataAnon } from '../CustomAnon';
import CustomField from '../CustomField';
import PayButtons from '../PayButtons';
import PayImgBox from '../PayImgBox';

export type CustomAnonFormInputs = {
  card_number: string;
  expiry: string;
  cvv: string;
  save: string;
  payer: string;
  amount: string;
  email: string;
  fio: string;
  agreement_id: string;
};

type CustomAnonProps = {
  onSubmit: OnSubmit;
  formData: {
    title: string;
  };
  mainContent: ContentData['main'];
  expiry?: string;
  cardMask?: string;
  userData: UserDataAnon;
};

const CustomAnonForm = (props: CustomAnonProps) => {
  const {
    onSubmit,
    formData,
    expiry,
    cardMask,
    userData,
    mainContent: { classesNames }
  } = props;

  const { cardValidation } = useValidation({
    noValidateExpiry: Boolean(expiry),
    noValidateCardNumber: Boolean(cardMask),
    validatePayer: true,
    validateAmount: true,
    validateEmail: true,
    validateFio: true,
    validateAgreementId: true
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting }
  } = useForm<CustomAnonFormInputs>({
    resolver: cardValidation,
    defaultValues: {
      card_number: cardMask || '',
      expiry: expiry || '',
      cvv: '',
      payer: '',
      email: '',
      fio: '',
      agreement_id: '',
      save: 'true'
    }
  });

  const { t } = useTranslation(['common']);
  const fioValue = watch('fio');
  const agreement_id = watch('agreement_id');
  const pay_amount = watch('amount');

  return (
    <section className="cardProof">
      <form className="container-s" onSubmit={handleSubmit(onSubmit)}>
        <h1>{t(`common:${formData.title}`)}</h1>
        <CustomWrapperFields
          label={t('common:payer_inn_phone')}
          error={errors['payer']}
        >
          <RawFields
            control={control}
            name={'payer'}
            parseValue={onlyNumber}
            maxLength={12}
            inputMode={'numeric'}
          />
        </CustomWrapperFields>

        <CustomWrapperFields label={t('common:fio')} error={errors['fio']}>
          <RawFields control={control} name={'fio'} />
        </CustomWrapperFields>

        <CustomWrapperFields
          label={t('common:agreement_id')}
          error={errors['agreement_id']}
        >
          <RawFields control={control} name={'agreement_id'} />
        </CustomWrapperFields>

        <CustomNumberFields
          control={control}
          name={'amount'}
          label={t('common:pay_amount')}
          error={errors['amount']}
        />
        <CustomWrapperFields label={t('common:email')} error={errors['email']}>
          <RawFields control={control} name={'email'} inputMode={'email'} />
        </CustomWrapperFields>
        <CustomField
          disabled={Boolean(cardMask)}
          control={control}
          name={'card_number'}
          label={t('common:card_number')}
          mask={cardMask ? '9999 99** **** 9999' : '9999 9999 9999 9999'}
          error={errors['card_number']}
          inputMode={'numeric'}
        />
        <div className="input-row">
          <CustomField
            disabled={Boolean(expiry)}
            control={control}
            name={'expiry'}
            label={t('common:expiry')}
            mask={'99/99'}
            error={errors['expiry']}
            inputMode={'numeric'}
          />
          <CustomField
            control={control}
            name={'cvv'}
            label="CVV"
            mask={'999'}
            comp={<CvvInfo />}
            error={errors['cvv']}
            inputMode={'numeric'}
          />
        </div>

        {watch('payer') &&
          watch('fio') &&
          watch('agreement_id') &&
          watch('amount') &&
          errors['payer'] === undefined &&
          errors['fio'] === undefined &&
          errors['agreement_id'] === undefined &&
          errors['amount'] === undefined && (
            <PayButtons
              isApple={userData.isApple}
              isGoogle={userData.isGoogle}
              payInfo={{
                fee: '',
                clientName: fioValue,
                agreementId: Number(agreement_id),
                fullAmount: pay_amount,
                payAmount: pay_amount
              }}
              onSubmit={onSubmit}
            />
          )}

        <div className="button-spin">
          <button
            disabled={isSubmitting}
            className={`btn ${
              classesNames && classesNames.btn ? classesNames.btn : ''
            }`}
            type="submit"
          >
            {t('common:confirm')}
          </button>
          {isSubmitting && (
            <Oval wrapperClass="button-spin__spinner" strokeWidth={5} />
          )}
        </div>
        {/* <UserAgreement /> */}
        <PayImgBox />
      </form>
    </section>
  );
};

export default CustomAnonForm;
