import { customPayAnon, customPayFailed } from '@root/constants/paths';
import { formPayData, getPaytechContentData } from '@root/constants/formData';
import CustomAnon from '@comp/CustomAnon';

const { main } = getPaytechContentData();

export const getCustomAnonRoutes = () => [
  {
    path: customPayAnon(),
    element: (
      <CustomAnon
        mainContent={main}
        formData={formPayData}
        redirectPaths={{ failed: () => customPayFailed() }}
      />
    )
  }
];
