import { useForm } from 'react-hook-form';
import PayImgBox from '@root/components/PayImgBox';
import { useTranslation } from 'react-i18next';
import UserAgreement from '@root/components/UserAgreement';
import ButtonWithSpin from '@comp/ButtonWithSpin';
import { getValidationResolver } from '@root/utils/validation';
import { useParams } from 'react-router-dom';
import { getLookupSubmitHandler } from '@root/handlers';
import { useUserEncodeData } from '@hooks/useUserEncodeData';
import { ContentData } from '@root/constants/formData/types';
import CustomField from '../CustomField';
import * as yup from 'yup';
import { useFingerPrint } from '@hooks/useFingerPrint';

export type LookupFormInputs = {
  lookupCode: string;
};

type CustomFormProps = {
  mainContent: ContentData['main'];
  formData: {
    title: string;
  };
};

const CustomLookupForm = (props: CustomFormProps) => {
  const {
    formData,
    mainContent: { classesNames }
  } = props;
  const { t } = useTranslation(['common', 'error']);
  const { id } = useParams();

  const { fingerPrint } = useFingerPrint();
  useUserEncodeData(id, fingerPrint);

  const { lookupSubmitHandler } = getLookupSubmitHandler(id);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = useForm<LookupFormInputs>({
    resolver: getValidationResolver({
      lookupCode: yup
        .string()
        .required(t('error:fill_fields'))
        .matches(/^\d{8}$/, t('error:not_valid_lookup_code'))
    }),
    defaultValues: {
      lookupCode: ''
    }
  });

  return (
    <section className="cardProof">
      <form
        className="container-s"
        onSubmit={handleSubmit(lookupSubmitHandler)}
      >
        <h1>{t(`common:${formData.title}`)}</h1>
        <CustomField
          control={control}
          name={'lookupCode'}
          label={t('common:lookup_code')}
          mask={'99999999'}
          error={errors['lookupCode']}
          inputMode={'numeric'}
        />
        <ButtonWithSpin disabled={isSubmitting} classesNames={classesNames} />
        <UserAgreement />
        <PayImgBox />
      </form>
    </section>
  );
};

export default CustomLookupForm;
