import './CoverForm.scss';
import { Oval } from 'react-loader-spinner';

type CoverFormComponentProps = {
  isLoading: boolean;
  className?: string;
};

const CoverFormComponent = (props: CoverFormComponentProps) => {
  const { isLoading, className } = props;

  const classNames = ['cover-form', className || ''].join(' ').trim();
  if (isLoading) {
    return (
      <div className={classNames}>
        <Oval wrapperClass="centred-block" />
      </div>
    );
  }
  return null;
};

export default CoverFormComponent;
