import { lazy } from 'react';
import CardInfoCreditPlus from '@pages/creditPlus/CardInfoCreditPlus';
import {
  creditPlusPay,
  creditPlusPaySuccess,
  creditPlusPayFailed
} from '@root/constants/paths';
import { formPayData } from '@root/constants/formData';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';

const MainCreditPlus = lazy(() => import('@pages/creditPlus/MainCreditPlus'));

export const getCreditPlusPayRoutes = () => [
  {
    path: creditPlusPay(),
    element: (
      <MainCreditPlus
        formData={formPayData}
        redirectPaths={{
          success: creditPlusPaySuccess,
          failed: creditPlusPayFailed
        }}
      >
        <CardInfoCreditPlus userData={undefined as never} />
      </MainCreditPlus>
    )
  },
  {
    path: creditPlusPaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: creditPlusPayFailed(),
    element: <CustomFailed redirectPath={creditPlusPay} />
  }
];
