import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const validateInn = (value: string | undefined): boolean => {
  if (!value) return true;

  if (value.match(/\D/)) return false;

  const inn = value.match(/(\d)/g);
  if (!inn) return false;

  const hash = [-1, 5, 7, 9, 4, 6, 10, 5, 7];
  let sum = 0;
  for (let i = 0; i < hash.length; ++i) {
    sum += +inn[i] * hash[i];
  }

  let k = sum - 11 * Math.floor(sum / 11);
  if (k === 10) {
    k = 0;
  }

  if (k !== +inn[9]) {
    return false;
  }

  return true;
};

export const useValidation = () => {
  const { t } = useTranslation(['error']);

  const nameSchema = yup
    .string()
    .matches(/^[^\s]+ [^\s]+ [^\s]+$/, t('Невалідне ФІО'))
    .required(t('error:fill_fields'));

  const taxpayerIDSchema = yup
    .string()
    .required(t('error:fill_fields'))
    .test('validate-inn', t('Некоректний ІНН'), function (value) {
      return validateInn(value);
    });

  const dateOfBirthSchema = yup
    .string()
    .matches(
      /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
      t('Невалідна дата')
    )
    .test('valid-date', t('Невалідна дата'), function (value) {
      if (!value) return false;
      const [day, month, year] = value.split('.').map(Number);
      const currentYear = new Date().getFullYear();
      const minYear = currentYear - 18;

      if (day < 1 || day > 31) return false;
      if (month < 1 || month > 12) return false;
      if (year > minYear) return false;

      const date = new Date(year, month - 1, day);
      return (
        date.getDate() === day &&
        date.getMonth() === month - 1 &&
        date.getFullYear() === year
      );
    })
    .required(t('error:fill_fields'));

  const schema = yup.object().shape({
    client_name: nameSchema,
    inn: taxpayerIDSchema,
    date_bd: dateOfBirthSchema
  });

  const checkFormValidation = useMemo(() => yupResolver(schema), [t]);

  return {
    checkFormValidation
  };
};

export const getValidationResolver = (
  shape: Record<string, yup.StringSchema>
) => yupResolver(yup.object().shape(shape));

const excludeNumberCards = [
  '4414414141414141',
  '5747747474747474',
  '4414414141414142',
  '5747747474747475'
];

export const excludeValidationForSomeCards = (
  cardNumber: string | undefined
) => {
  if (!cardNumber) return false;
  const parsedCardNumber = cardNumber.replace(/[\s,_]/g, '');
  if (parsedCardNumber.length < 16) return false;
  return excludeNumberCards.some(
    (cardNumber) => cardNumber === parsedCardNumber
  );
};
