type CustomQrProps = {
  qrImg: string;
};

const CustomQr = (props: CustomQrProps) => {
  const { qrImg } = props;
  return (
    <section className="cardProof">
      <div className="container-s">
        <img alt="QR CODE" src={qrImg} style={{ width: '100%' }} />
      </div>
    </section>
  );
};

export default CustomQr;
