import { statusPageRoute } from './StatusPageRoute';
import { successPageRoute } from './SuccessPageRoute';
import { avansCreditRoute } from './avansCreditRoute';
import { credit7Route } from './credit7Route';
import { creditPlusRoute } from './creditPlusRoute';
import { customRoutes } from './customRoute';
import { error404Route } from './error404Route';
import { selfieRoutes } from './selfieRoutes';
import { slonRoutes } from './slonRoutes';
import { starFinRoute } from './starfinRoute';
import { sunCreditRoute } from './sunCreditRoute';

export const routes = [
  customRoutes,
  credit7Route,
  selfieRoutes,
  slonRoutes,
  creditPlusRoute,
  statusPageRoute,
  successPageRoute,
  error404Route,
  avansCreditRoute,
  sunCreditRoute,
  starFinRoute
];
