import { useEffect } from 'react';
import { sendUserEncodeData } from '@root/api/getUserInfo';
import { detectUserDevice } from '@root/utils/detectUserDevice';
import axios from 'axios';

export const useUserEncodeData = (
  session: string | undefined,
  fingerPrint: string
) => {
  useEffect(() => {
    if (!fingerPrint) return;
    axios({
      method: 'get',
      url: 'https://get.geojs.io/v1/ip/geo.json'
    }).then((res) => {
      const { data } = res;
      const userDataBase64 = btoa(
        JSON.stringify({ browser: { ...detectUserDevice() }, geo: { ...data } })
      );
      if (session) {
        return sendUserEncodeData(
          session,
          {
            frame: userDataBase64
          },
          fingerPrint
        );
      }
    });
  }, [fingerPrint, session]);
};
