import { OnSubmit } from '~/hooks/useOnSubmit';
import { UserData } from '~/utils/formatUserData';
export type Props = {
  userData: UserData;
  onSubmit: OnSubmit;
  urlContainsPay?: boolean;
};
export type FormInputs = {
  save: string;
  card_number: string;
  expiry: string;
  cvv: string;
  onSubmit?: OnSubmit;
};
export const textInForm = `Зазначаючи реквізити електронного платіжного засобу (платіжної
	картки) для безготівкового перерахування коштів на рахунок,
	списання коштів із рахунку (погашення кредиту), позичальник
	усвідомлює, що ці реквізити зберігатимуться кредитодавцем або
	особою, з якою в кредитодавця є договірні відносини, та можуть
	використовуватися для списання коштів з рахунку для погашення
	вимог за договором про споживчий кредит. У разі настання такого
	списання позичальник має право в односторонньому порядку
	відмовитися або припинити списання коштів у визначеному договором
	порядку`;

export const initialValues = (userData: UserData): FormInputs => {
  return {
    save: 'true',
    card_number: userData.cardMask || '',
    expiry: userData.expiry || '',
    cvv: ''
  };
};
