export const CREDIT7_MERCHANT = '3';

export const credit7Verify = (param?: string) => {
  if (param) {
    return `/${CREDIT7_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const credit7VerifySuccess = (param?: string) => {
  if (param) {
    return `/${CREDIT7_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const credit7VerifyFailed = (param?: string) => {
  if (param) {
    return `/${CREDIT7_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const credit7Pay = (param?: string) => {
  if (param) {
    return `/${CREDIT7_MERCHANT}/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const credit7PaySuccess = (param?: string) => {
  if (param) {
    return `/${CREDIT7_MERCHANT}/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const credit7PayFailed = (param?: string) => {
  if (param) {
    return `/${CREDIT7_MERCHANT}/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};
