import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import warningIcon from '@root/images/avansCredit/warning.svg';
import style from '@root/scss/avansCredit/index.module.scss';
import { textInForm } from './helper/helper';

export const AccordionComponent = () => {
  return (
    <>
      <div className={style.accordion}>
        <Accordion className={style.accordionContainer} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <span
              className={style.accordionText}
              style={{ marginRight: '12px' }}
            >
              <img src={warningIcon} alt="" />
            </span>
            <span className={style.accordionText}>Попередження</span>
          </AccordionSummary>
          <AccordionDetails className={style.accordionWarning}>
            {textInForm}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
