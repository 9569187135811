import style from '@root/scss/credit7/index.module.scss';

const LogoBlock = (props: { imgs: string[] }) => {
  const { imgs } = props;
  return (
    <div className={style.blockForm__logotypes}>
      {imgs.map((img) => (
        <img src={img} alt="" key={img} />
      ))}
    </div>
  );
};

export default LogoBlock;
