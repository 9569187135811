import { MainK7 } from '@pages/credit7/MainK7';
import { CardInfoK7 } from '@pages/credit7/CardInfoK7';
import {
  credit7Pay,
  credit7PaySuccess,
  credit7PayFailed
} from '@root/constants/paths';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import { formPayData, getCredit7ContentData } from '@root/constants/formData';

const { main } = getCredit7ContentData();

export const getCredit7PayRoutes = () => [
  {
    path: credit7Pay(),
    element: (
      <MainK7
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: credit7PaySuccess,
          failed: credit7PayFailed
        }}
      >
        <CardInfoK7 userData={undefined as never} />
      </MainK7>
    )
  },
  {
    path: credit7PaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: credit7PayFailed(),
    element: <CustomFailed redirectPath={credit7Pay} />
  }
];
